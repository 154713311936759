import(/* webpackMode: "eager", webpackExports: ["Inner"] */ "/home/runner/work/rimo-frontend/rimo-frontend/apps/frontend/app/inner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/rimo-frontend/rimo-frontend/apps/frontend/app/registry.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/rimo-frontend/rimo-frontend/apps/frontend/styles/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["ForceHttps"] */ "/home/runner/work/rimo-frontend/rimo-frontend/libs/frontend/src/components/ForceHttps.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/home/runner/work/rimo-frontend/rimo-frontend/libs/ui/src/components/ui/toaster.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/rimo-frontend/rimo-frontend/node_modules/.pnpm/next@15.2.3_@babel+core@7.26.0_@opentelemetry+api@1.9.0_babel-plugin-macros@3.1.0_react-dom@1_bu7pq26c6j7aek3li7k7zpsj3e/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/rimo-frontend/rimo-frontend/node_modules/.pnpm/react-image-crop@10.1.8_react@19.0.0/node_modules/react-image-crop/dist/ReactCrop.css");
